import { CustomI18n as I18n } from '/src/utils/custom_i18n'
import { includeDateColumns } from '/src/models/concerns/datetime_columns'
import { isBlank } from '/src/utils/boolean_refinements'

export const hasInspectedServices = (inspection) => {
  return inspection?.inspected_services?.length > 0 || inspection?.service_ids?.length > 0
}

export const groupInspectionsByCategory = (inspectionTemplates, inspectionCategories) => {
  const groups = {}

  inspectionTemplates.forEach((inspection) => {
    if (!inspection.inspection_category_id || !inspectionCategories) {
      groups[inspection.title] = { template: inspection }
      return
    }

    const inspectionCategory = inspectionCategories[inspection.inspection_category_id]?.description
    if (Object.hasOwn(groups, inspectionCategory)) {
      groups[inspectionCategory].category.push(inspection)
      return
    }

    groups[inspectionCategory] = { category: [inspection] }
  })

  return groups
}

const hasDescription = (dataItem) => {
  return dataItem && 'description' in dataItem
}

const hasTagNumber = (dataItem) => {
  return dataItem && dataItem.tag_number !== undefined
}

export default class InspectionModel {
  static paramName = 'inspection'

  name = I18n.t('inspections.title')

  singularName = I18n.t('inspections.description')

  templateType = 'inspection'

  route = 'inspections'

  complementaryRoute = 'inspections/with_inspected_data'

  flexible = true

  initialColumns = [
    {
      id: 1,
      description: 'id',
      title: I18n.t('inspections.id'),
      hideOnGrid: true,
      hideOnForm: true,
      width: 120,
      type: 'integer'
    },
    {
      id: 2,
      description: 'request_id',
      title: I18n.t('requests.id'),
      hideOnForm: true,
      width: 150,
      type: 'integer'
    },
    {
      id: 3,
      description: 'request',
      title: I18n.t('requests.id'),
      foreignKey: 'requests',
      foreignAttribute: 'request_id',
      hideOnGrid: true,
      orderOnForm: 1,
      required: true,
      searchFields: ['id', 'reason', 'comments'],
      searchExtraQuery: {
        where: {
          '[request_statuses][i18n_id]': [
            'registered',
            'confirmed',
            'in_scoping',
            'authorization_pending',
            'in_planning',
            'in_performance',
            'on_hold',
            'done'
          ]
        }
      },
      searchRoute: 'requests',
      textDisplayFields: ['id', 'reason', 'comments'],
      textField: 'reason',
      type: 'search'
    },
    {
      id: 4,
      description: 'inspect_status',
      title: I18n.t('inspections.inspect_status_id'),
      sortable: false,
      filterable: false,
      hideOnForm: true,
      width: 200,
      type: 'text'
    },
    {
      id: 5,
      description: 'inspect_date',
      orderOnForm: 3,
      title: I18n.t('inspections.inspect_date'),
      width: 200,
      type: 'date'
    },
    {
      id: 6,
      description: 'number',
      title: '#',
      hideOnForm: true,
      width: 100,
      type: 'string'
    },
    {
      id: 7,
      description: 'reason',
      field: 'request.reason',
      sortable: false,
      title: I18n.t('requests.reason'),
      hideOnForm: true,
      width: 200,
      type: 'string'
    },
    {
      id: 8,
      description: 'comments',
      field: 'request.comments',
      sortable: false,
      title: I18n.t('requests.comments'),
      hideOnForm: true,
      width: 200,
      type: 'string'
    },
    {
      id: 10,
      description: 'responsible',
      field: 'responsible.full_name',
      onSort: (_) => ({ description: 'responsible_id' }),
      title: I18n.t('inspections.responsible_id'),
      foreignKey: 'employees',
      foreignAttribute: 'responsible_id',
      required: true,
      filterable: true,
      orderOnForm: 4,
      searchFields: ['name', 'last_name'],
      searchExtraQuery: {
        where: { is_inspection_responsible: 1 }
      },
      searchRoute: 'employees',
      textDisplayFields: ['full_name'],
      type: 'search'
    },
    {
      id: 11,
      description: 'inspected_type',
      title: I18n.t('inspections.module'),
      orderOnForm: 2,
      filterable: false,
      width: 200,
      type: 'string',
      translationPath: 'inspections.inspected_modules'
    },
    {
      id: 12,
      description: 'description',
      title: I18n.t('inspections.description_title'),
      hideOnForm: true,
      readOnly: true,
      sortable: false,
      filterable: true,
      gridFilterType: 'inspected',
      filterOperators: ['contains'],
      isRetrieving: (dataItem) => !hasDescription(dataItem),
      type: 'string'
    },
    {
      id: 13,
      description: 'tag_number',
      title: I18n.t('scaffoldings.tag_number'),
      sortable: false,
      filterable: true,
      readOnly: true,
      hideOnForm: true,
      width: 150,
      gridFilterType: 'inspected',
      filterOperators: ['eq'],
      isRetrieving: (dataItem) => !hasTagNumber(dataItem),
      type: 'string'
    },
    {
      id: 16,
      description: 'area.description',
      forceUseDescription: true,
      title: I18n.t('requests.area'),
      sortable: false,
      filterable: false,
      readOnly: true,
      hideOnForm: true,
      isRetrieving: (dataItem) => !(dataItem && 'area' in dataItem),
      type: 'string'
    },
    {
      id: 17,
      description: 'subarea.description',
      forceUseDescription: true,
      title: I18n.t('requests.subarea'),
      sortable: false,
      filterable: false,
      readOnly: true,
      hideOnForm: true,
      isRetrieving: (dataItem) => !(dataItem && 'subarea' in dataItem),
      type: 'string'
    },
    {
      id: 14,
      description: 'recurrency_uuid',
      hideOnForm: true,
      hideOnGrid: true,
      width: 200,
      type: 'string'
    },
    {
      id: 15,
      description: 'eav_template_id',
      hideOnForm: true,
      hideOnGrid: true,
      width: 200,
      type: 'integer'
    }
  ]

  staticColumns = includeDateColumns(this.initialColumns)

  constructor(inspectedFormatter, isInBatch = false, type = '') {
    const dpmsIdIndex = 2
    const responsibleIndex = 9
    const reasonIndex = 8
    this.paramName = InspectionModel.paramName
    this.columns = this.staticColumns
    const inspectedType = this.columns.find((e) => e.description === 'inspected_type')
    inspectedType.formatter = inspectedFormatter

    if (isInBatch) {
      ;[dpmsIdIndex, responsibleIndex].forEach((index) => {
        this.columns[index].hideOnForm = true
        this.columns[index].required = false
      })
      this.columns[reasonIndex].required = false
    }

    if (type === 'Scaffolding') {
      this.columns[dpmsIdIndex].hideOnForm = true
      this.columns[dpmsIdIndex].required = false
    }
  }
}
