/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { MdArrowForward, MdArrowBack } from 'react-icons/md'
import I18n from '/src/utils/translations'
import useFetchAPI from '/src/hooks/api/fetch_api'
import useFetch from '/src/hooks/api/fetch'
import useFirstFlexibleColumn from '/src/hooks/first_flexible_column'
import InputSearch from '/src/ui/core/inputs/input_search'
import InputDrop from '/src/ui/core/inputs/input_drop'
import Modal from '/src/ui/core/popups/modal'
import { isJson } from '/src/utils/string'
import { isEmpty } from '/src/utils/object'
import { ContainsOperator } from '/src/models/concerns/filter_operators'
import '/src/static/css/core/popups/filter_steps_modal.css'

// eslint-disable-next-line max-lines-per-function
export default function InspectedModuleFilterStepsModal({
  filters,
  resultTitle,
  currentValues,
  displayParentValues,
  onDone,
  onClose,
  eavTemplate
}) {
  const { fetch } = useFetch()
  const [values, setValues] = useState(currentValues)
  const [firstInspectedServiceColumn, setFirstInspectedServiceColumn] = useState()
  const [displayValues, setDisplayValues] = useState(displayParentValues)
  const [index, setIndex] = useState(0)
  const [stepStatus, setStepStatus] = useState('not_started')
  const [jumpStack, setJumpStack] = useState([])
  const [selectedValue, setSelectedValue] = useState(null)
  const [selectedDisplayValue, setSelectedDisplayValue] = useState(null)
  const [skipping, setSkipping] = useState(true)
  const [status, setStatus] = useState('')
  const [responseData, setResponseData] = useState([])
  const [inspectedColumn, setInspectedColumn] = useState()
  const dropRef = useRef(null)
  const fetchEavColumns = useFetchAPI('eav_columns')
  const filterFirstFlexibleColumn = useFirstFlexibleColumn()
  const { inspection_detail: inspectionDetail = {} } = eavTemplate

  const findJump = (direction, description, localSelectedValue) => {
    let jump = direction

    const jumpMapping = {
      request_id: {
        Request: 4,
        Scaffolding: 3
      },
      inspected_id: {
        Scope: 2,
        Progress: 2,
        Scaffolding: 2
      }
    }

    if (jumpMapping[description]?.[values?.inspected_type] !== undefined) {
      jump = jumpMapping[description][values.inspected_type]
    }

    if (localSelectedValue === 'Scaffolding') {
      jump = 3
    }

    return Math.max(Math.min(index + jump, filters.length), 0)
  }

  const actionClick = (localSelectedValue, localSelectedDisplayValue) => {
    const conditions = [
      { index: 2, value: values.inspected_template },
      { index: 3, value: values.inspected_id }
    ]

    conditions.forEach((condition) => {
      if (index === condition.index && localSelectedValue !== condition.value) {
        delete values?.service_ids
        delete displayValues?.service_ids
      }
    })

    const { description } = filters[index]
    const newValues = { ...values, [description]: localSelectedValue }
    const newDisplayValues = { ...displayValues, [description]: localSelectedDisplayValue }

    if (description === 'inspected_template') {
      fetchEavColumns.fetchAPI({
        requestAction: 'READ',
        httpAction: 'get',
        query: { where: { eav_template_id: localSelectedValue } }
      })

      fetch(
        'eav_templates',
        {
          requestAction: 'READ',
          httpAction: 'get',
          query: { where: { parent_template_id: localSelectedValue } }
        },
        {
          onSuccess: async ({ data }) => {
            const { data: eavColumns } = await fetch('eav_columns', {
              requestAction: 'READ',
              httpAction: 'get',
              query: { where: { eav_template_id: data.data[0]?.id } }
            })

            const newInspectedServiceColumn = filterFirstFlexibleColumn(eavColumns.data) || {}

            setFirstInspectedServiceColumn(newInspectedServiceColumn)
          }
        }
      )
    }

    if (localSelectedValue === 'Scaffolding' && !!newValues.request_id) {
      ;['request_id', 'inspected_template'].forEach((key) => {
        if (newValues[key]) delete newValues[key]
      })
    }

    if (['Progress', 'Scope'].includes(localSelectedValue) && newValues.service_ids) {
      delete newValues.service_ids
      delete newDisplayValues.service_ids
    }

    setValues(newValues)
    setDisplayValues(newDisplayValues)

    const jumpTo = findJump(1, description, localSelectedValue)
    setJumpStack([...jumpStack, Math.abs(index - jumpTo)])
    setIndex(jumpTo)
  }

  const cancelClick = () => {
    const currentjumpStack = [...jumpStack]
    const lastJump = currentjumpStack.pop() * -1

    const newIndex = findJump(lastJump)
    setIndex(newIndex)
    setJumpStack(currentjumpStack)
  }

  const doneClick = () => onDone(values, displayValues)

  const disabled = () => stepStatus !== 'loaded'

  const onSelectedValue = (_ignored, value, itemValue) => {
    const { description } = filters[index]
    const useitemValue = itemValue && itemValue.computed_text_field !== undefined

    setSelectedValue(value)

    if (description === 'service_ids') {
      setSelectedDisplayValue(itemValue.map(({ computed_text_field: computedText }) => computedText))
    } else if (value && selectedValue !== value) {
      actionClick(value, useitemValue ? itemValue.computed_text_field : value)
    }
  }

  const onFetch = (fetchedData) => {
    setResponseData(fetchedData)
    setStatus('SUCCESS')
  }

  const props = {
    className: 'selector-combobox',
    popupClassName: 'filters-steps-combobox-list',
    iconClassName: 'k-i-search',
    onChange: onSelectedValue,
    onFetch,
    ref: dropRef,
    appendTo: '#filter-steps-options',
    forceCombobox: true,
    readOnly: false,
    opened: true
  }

  const [inputProps, setInputProps] = useState(props)

  useEffect(() => {
    if (index >= filters.length) return
    const filter = filters[index].description
    setSelectedValue(values[filter])
  }, [index, filters])

  useEffect(() => {
    if (fetchEavColumns.status !== 'SUCCESS') return

    const newInspectedColumn = filterFirstFlexibleColumn(fetchEavColumns.responseData.data) || {}
    setInspectedColumn(newInspectedColumn)
  }, [fetchEavColumns.status, fetchEavColumns.responseData])

  useEffect(() => {
    if (index === filters.length) return

    const filter = filters[index]
    const isInspectedId = filter.description === 'inspected_id' && selectedValue !== 'Scaffolding'

    if (isInspectedId && !inspectedColumn) {
      if (values[filters[index]?.description] && inputProps)
        setInputProps((prevInputProps) => ({
          ...prevInputProps,
          value: values[filters[index]?.description]
        }))
      setStatus('')
      setStepStatus('loading')
      return
    }

    if (index === 1 && inspectionDetail.inspection_disciplines?.length > 0) {
      filter.searchExtraQuery.where.discipline_id = inspectionDetail.inspection_disciplines.join(',')
    }
    const searchExtraQuery = filter.searchExtraQuery || (filter.queryBuilder && filter.queryBuilder(values))
    const searchRoute = filter.searchRoute || (filter.searchRouteBuilder && filter.searchRouteBuilder(values))

    if (!filter.searchRoute && filter.metadata) {
      setResponseData(JSON.parse(filter.metadata))
      setStatus('SUCCESS')
      setStepStatus('loaded')
    } else {
      setStatus('')
      setStepStatus('loading')
    }

    let textDisplayFields =
      isInspectedId && inspectedColumn
        ? [...filter.textDisplayFields, inspectedColumn.description]
        : filter.textDisplayFields

    let searchFields = isInspectedId && inspectedColumn ? [inspectedColumn.description] : filter.searchFields

    if (
      (values?.inspected_type === 'estimate_services' && index === 4) ||
      (values?.inspected_type === 'progress_services' && index === 4)
    ) {
      textDisplayFields = [...filter?.textDisplayFields, firstInspectedServiceColumn?.description]
      searchFields = [firstInspectedServiceColumn?.description]
    }

    if (selectedValue === 'Scaffolding') {
      textDisplayFields = ['tag_number', 'description']
      searchFields = ['tag_number', 'description']
    }

    setInputProps({
      ...props,
      searchRoute,
      searchFields,
      searchExtraQuery,
      textDisplayFields,
      metadata: filter.metadata,
      multiple: filter.multiple,
      distinct: filter.distinct,
      type: filter.type,
      value: values[filter.description],
      index
    })
    setSkipping(true)
  }, [values, index, filters, inspectedColumn])

  useEffect(() => {
    if (stepStatus !== 'loading' || status !== 'SUCCESS') return
    setStepStatus('loaded')
  }, [stepStatus, status, responseData, index, skipping])

  useEffect(() => {
    if (stepStatus === 'loaded') dropRef.current.focus()
  }, [stepStatus])

  useEffect(() => {
    if (stepStatus !== 'loaded') return

    setSkipping(true)
  }, [stepStatus])

  const totalSteps = resultTitle ? filters.length + 1 : filters.length

  const isLastStep = () => index === totalSteps - 1

  const renderKeys = (key) => {
    return filters.find((column) => column.description === key).title
  }

  const renderValues = (key) => {
    Object.keys(displayValues).forEach((keyValue) => {
      if (displayValues[keyValue] === null) {
        displayValues[keyValue] = displayParentValues[keyValue]
      }
    })

    if (key === 'inspected_type') {
      return I18n.t(`inspections.inspected_modules.${displayValues[key].toLowerCase()}`)
    }

    if (key === 'service_ids') {
      return displayValues[key].join(', ')
    }

    return displayValues[key]
  }

  const filterModules = (modules, reference) => {
    return modules.filter((module) => reference.inspection_modules.includes(module.id))
  }

  const renderSearch = () => {
    const { type, index: newIndex, metadata } = inputProps
    const { inspected_type: inspectedType } = values || {}

    const prefix = inspectedType === 'Scaffolding' ? 'TAG #' : ''

    const searchProps = {
      ...inputProps,
      ...(newIndex === 4 && { searchOperator: ContainsOperator })
    }

    const options = isJson(metadata) ? JSON.parse(metadata) : null
    const filteredOptions =
      newIndex === 0 && inspectionDetail.inspection_modules?.length > 0
        ? filterModules(options, inspectionDetail)
        : options

    switch (type) {
      case 'search':
        return <InputSearch key={newIndex} inputProps={searchProps} prefix={prefix} />
      case 'drop':
        return <InputDrop key={newIndex} inputProps={{ ...inputProps, options: filteredOptions }} />
      default:
        return <b>{I18n.t('notifications.warning')}</b>
    }
  }

  const prevTempButton = () => {
    return (
      <div className="filter-steps-action-buttons">
        <div id="filter-steps-prev-button" className="filter-steps-button temp-prev">
          {index > 0 && (
            <button className="form-cancel-button" onClick={() => cancelClick()} type="button">
              <MdArrowBack />
              {` ${I18n.t('actions.prev')}`}
            </button>
          )}
        </div>
      </div>
    )
  }

  return (
    <Modal
      loading={{ status: stepStatus === 'loading', text: I18n.t('main_dialog.loading_title') }}
      height={90}
      width={50}
      title={`${I18n.t('filter_steps_modal.step')} ${index + 1}/${totalSteps}`}
      onClose={onClose}
      className="k-window-wrapper modal-wrapper"
      appendToBody
      closable
    >
      <div key={index} id="filter-steps-modal" className="filter-steps-modal">
        <div id="filter-steps-title" className="filter-steps-title">
          {index === filters.length ? I18n.t('filter_steps_modal.filter_result') : filters[index].title}
          <span className="count">
            {
              (index === 0 && inspectionDetail.inspection_modules?.length > 0
                ? filterModules(responseData, inspectionDetail)
                : responseData
              ).length
            }
          </span>
        </div>

        <div id="filter-steps-subtitle" className="filter-steps-subtitle">
          {`${I18n.t('filter_steps_modal.subtitle', {
            title: index === filters.length ? I18n.t('filter_steps_modal.filter_result') : filters[index].title
          })}`}
        </div>

        <div id="filter-steps-content" className="filter-steps-content">
          {index === filters.length ? (
            <div className="filter-result">
              <div className="result-title">
                <span>{resultTitle}</span>
                <img src="/static/svg/tick_blue.svg" alt="Blue tick" />
              </div>
              <div className="result-filters">
                {Object.keys(displayValues)
                  .filter((key) => values[key])
                  .map((k) => (
                    <p key={k}>
                      {renderKeys(k)}: {renderValues(k)}
                    </p>
                  ))}
              </div>
            </div>
          ) : (
            <React.Fragment>
              {inputProps && inputProps.type && index === inputProps.index && renderSearch()}
              <div id="filter-steps-options" className="filter-steps-options" />
            </React.Fragment>
          )}
        </div>

        {stepStatus !== 'loading' && (
          <div className="filter-steps-action-buttons">
            <div id="filter-steps-prev-button" className="filter-steps-button">
              {index > 0 && (
                <button
                  className="form-cancel-button"
                  disabled={disabled()}
                  onClick={() => cancelClick()}
                  type="button"
                >
                  <MdArrowBack />
                  {` ${I18n.t('actions.prev')}`}
                </button>
              )}
            </div>

            <div id="filter-steps-next-button" className="filter-steps-button">
              {isLastStep() ? (
                <button className="next-button" onClick={doneClick} type="button">
                  {`${I18n.t('actions.done')}`}
                </button>
              ) : (
                <React.Fragment>
                  <button
                    className="next-button"
                    disabled={disabled() || isEmpty(responseData) || !selectedValue}
                    onClick={() => actionClick(selectedValue, selectedDisplayValue)}
                    type="button"
                  >
                    {`${I18n.t('actions.next')} `}
                    <MdArrowForward />
                  </button>
                </React.Fragment>
              )}
            </div>
          </div>
        )}
        {stepStatus === 'loading' && prevTempButton()}
      </div>
    </Modal>
  )
}

InspectedModuleFilterStepsModal.propTypes = {
  filters: PropTypes.oneOfType([PropTypes.array]).isRequired,
  resultTitle: PropTypes.string,
  currentValues: PropTypes.oneOfType([PropTypes.object]),
  displayParentValues: PropTypes.oneOfType([PropTypes.object]),
  onDone: PropTypes.func,
  onClose: PropTypes.func,
  eavTemplate: PropTypes.oneOfType([PropTypes.object])
}

InspectedModuleFilterStepsModal.defaultProps = {
  resultTitle: undefined,
  currentValues: {},
  displayParentValues: {},
  onDone: () => {},
  onClose: () => {},
  eavTemplate: {}
}
