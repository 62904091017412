import React from 'react'
import PropTypes from 'prop-types'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import '/src/static/css/core/badges/flag_icons_badge.css'

export default function FlagIconsBadge({ flags }) {
  if (!flags.length) return null

  return (
    <CustomTooltip parentTitle>
      <div className="flag-icons-badge">
        {flags.map((flag) => (
          <div
            key={flag.title}
            className={`flag-icons-badge__icon flag-icons-badge__icon--${flag.type}`}
            title={flag.title}
          >
            {flag.icon}
          </div>
        ))}
      </div>
    </CustomTooltip>
  )
}

FlagIconsBadge.propTypes = {
  flags: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      title: PropTypes.string,
      icon: PropTypes.element
    })
  )
}

FlagIconsBadge.defaultProps = {
  flags: []
}
