import { isEmpty } from 'lodash'
import { CustomI18n as I18n } from '/src/utils/custom_i18n'
import { normalizeDateToString } from '/src/utils/project_formatter'

export default class ScaffoldingPartActionModel {
  constructor() {
    this.paramName = ScaffoldingPartActionModel.paramName
  }

  static paramName = 'scaffolding_part_action'

  name = I18n.t('scaffolding_part_actions.title')

  singularName = I18n.t('scaffolding_part_actions.singular')

  templateType = 'scaffolding_part_action'

  route = 'scaffolding_part_actions'

  flexible = false

  templateId = -3

  columns = [
    {
      id: 1,
      description: 'modification_type',
      web_form_width: '100',
      type: 'string',
      watchUpdate: true,
      middleToggle: {
        leftTitle: I18n.t('scaffolding_part_actions.modification_type.erect'),
        rightTitle: I18n.t('scaffolding_part_actions.modification_type.dismantle'),
        trueValue: 'dismantle',
        falseValue: 'erect'
      },
      section: 1,
      orderOnForm: 1
    },
    {
      id: 2,
      description: 'date',
      required: true,
      title: I18n.t('scaffolding_part_actions.date'),
      type: 'date',
      default: normalizeDateToString(new Date()),
      web_form_width: '100',
      section: 1,
      orderOnForm: 2
    },
    {
      id: 3,
      description: 'length',
      required: true,
      title: I18n.t('scaffolding_part_actions.length'),
      type: 'decimal',
      web_form_width: '33',
      section: 1,
      orderOnForm: 3
    },
    {
      id: 4,
      description: 'width',
      required: true,
      title: I18n.t('scaffolding_part_actions.width'),
      type: 'decimal',
      web_form_width: '33',
      section: 1,
      orderOnForm: 4
    },
    {
      id: 5,
      description: 'height',
      required: true,
      title: I18n.t('scaffolding_part_actions.height'),
      type: 'decimal',
      web_form_width: '33',
      section: 1,
      orderOnForm: 5
    },
    {
      id: 6,
      description: 'total',
      title: I18n.t('scaffolding_part_actions.total'),
      type: 'fixed_formula',
      dependency: ['length', 'width', 'height'],
      formula: function fn(values, dataItem) {
        const { dimensionType: type } = dataItem

        if (isEmpty(values)) return dataItem?.total
        if (type === 'm') return values[0]
        if (type === 'm2') return values[0] * values[1]
        return values[0] * values[1] * values[2]
      },
      web_form_width: '100',
      section: 1,
      orderOnForm: 6
    },
    {
      id: 7,
      description: 'comment',
      title: I18n.t('scaffolding_part_actions.comment'),
      web_form_width: '100',
      type: 'string',
      hideOnForm: function fn(dataItem) {
        return dataItem.action_type === 'build'
      },
      required: true,
      section: 1,
      orderOnForm: 7
    },
    {
      id: 8,
      description: 'work_dismantle',
      web_form_width: '100',
      default: false,
      type: 'boolean',
      watchUpdate: true,
      middleToggle: {
        leftTitle: I18n.t('scaffolding_part_actions.work_dismantle')
      },
      value: false,
      hideOnForm: function fn(dataItem) {
        return !dataItem || !(dataItem?.modification_type === 'dismantle')
      },
      visible_on_web: false,
      section: 1,
      orderOnForm: 8
    },
    {
      id: 9,
      description: 'work_order_id',
      placeholder: (() => {
        return I18n.t('scaffolding_part_actions.work_order.choose_wo')
      })(),
      fieldFormatter: function fn(dataItem) {
        const workOrder = dataItem.work_order || {}
        const textDisplayFields = ['reason', 'comments']
        const workOrderDisplayValue = textDisplayFields.map((field) => workOrder[field]).filter((value) => value)
        return workOrderDisplayValue.join(' - ')
      },
      foreignKey: 'requests',
      foreignAttribute: 'work_order_id',
      searchFields: ['id', 'reason', 'comments'],
      searchExtraQuery: {
        where: {
          '[request_statuses][i18n_id]': ['in_planning', 'in_performance']
        },
        order: {
          id: 'desc'
        }
      },
      searchRoute: 'requests',
      textDisplayFields: ['id', 'reason', 'comments'],
      textField: 'reason',
      type: 'search',
      gridFilterTable: 'main_work_order_erect',
      gridFilterType: 'contains_or',
      gridFilterFields: ['reason', 'comments'],
      web_form_width: '100',
      hideOnForm: function fn(dataItem) {
        return !dataItem || !(dataItem?.work_dismantle === true && dataItem?.modification_type === 'dismantle')
      },
      visible_on_web: false,
      section: 1,
      orderOnForm: 9
    },
    {
      id: 10,
      description: 'action_type',
      web_form_width: '100',
      width: 100,
      type: 'string',
      hideOnForm: () => true,
      visible_on_web: false,
      section: 1
    }
  ]

  sections = []
}
