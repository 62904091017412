import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import { useStore } from 'react-context-hook'
import FlagIconsBadge from '/src/ui/core/badges/flag_icons_badge'
import { ReactComponent as FeatureSearch } from '/public/static/svg/feature_search_icon'
import { MdBorderColor, MdNewReleases } from 'react-icons/md'
import { BsFillTagFill } from 'react-icons/bs'
import '/src/static/css/domain/progress_services/cells.css'

function getStatusFlag(statusId, statuses) {
  if (!statusId || !statuses[statusId]?.i18n_id) return null

  const statusMapping = {
    in_planning: {
      title: I18n.t('progress_services.not_planned'),
      type: 'not-planned',
      icon: <BsFillTagFill />
    },
    planned: {
      title: I18n.t('progress_services.planned'),
      type: 'planned',
      icon: <BsFillTagFill />
    },
    canceled: {
      title: I18n.t('progress_services.canceled'),
      type: 'canceled',
      icon: <BsFillTagFill />
    }
  }

  return statusMapping[statuses[statusId].i18n_id] || null
}

function getAdditionalFlag(isExtra, isRevised) {
  if (isExtra) {
    return {
      title: I18n.t('progresses.extra'),
      type: 'extra',
      icon: <MdNewReleases />
    }
  }

  if (isRevised) {
    return {
      title: I18n.t('progresses.revised'),
      type: 'revised',
      icon: <MdBorderColor />
    }
  }

  return null
}

function getInspectionFlag(hasInspectionPending) {
  if (hasInspectionPending) {
    return {
      title: I18n.t('estimate_services.statuses.inspected_flag'),
      type: 'inspection-pending',
      icon: <FeatureSearch />
    }
  }
  return null
}

export default function FlagColumn({ dataItem, request }) {
  const [statuses] = useStore('progress_service_statuses')

  if (!statuses) return null

  const {
    estimate_service: estimateService,
    quantity,
    progress_service_status_id: statusId,
    inspected_flag: inspectedFlag
  } = dataItem

  const notSkipScoping = request && !request.skip_scoping
  const isExtra = (!estimateService || estimateService.quantity === null) && notSkipScoping
  const isRevised = estimateService && estimateService.quantity !== quantity
  const hasInspectionPending = inspectedFlag && inspectedFlag === 'pending'

  const flags = [
    getStatusFlag(statusId, statuses),
    getAdditionalFlag(isExtra, isRevised),
    getInspectionFlag(hasInspectionPending)
  ].filter(Boolean)

  return <FlagIconsBadge flags={flags} />
}

FlagColumn.propTypes = {
  dataItem: PropTypes.shape({
    quantity: PropTypes.number,
    progress_service_status_id: PropTypes.number,
    inspected_flag: PropTypes.string,
    estimate_service: PropTypes.shape({
      quantity: PropTypes.number
    })
  }).isRequired,
  request: PropTypes.shape({
    skip_scoping: PropTypes.bool
  })
}

FlagColumn.defaultProps = {
  request: {}
}
