import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useStore } from 'react-context-hook'
import { useHistory } from 'react-router-dom'
import { useQueryParam, NumberParam } from 'use-query-params'
import useRequestsPinned from '/src/hooks/requests_pinned'
import useFetchAPI from '/src/hooks/api/fetch_api'
import useTranslations from '/src/hooks/api/translations'
import useRequestGridFilter from '/src/hooks/request_grid_filter'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import useQueryParamWithPrevious from '/src/hooks/query_param_with_previous'
import useFetch from '/src/hooks/api/fetch'
import BusEvents from '/src/hooks/bus/bus_events'
import { SIDE_PANEL_CLOSED, SIDE_PANEL_OPENED } from '/src/utils/constants/grid'
import I18n from '/src/utils/translations.js'
import Layout from '/src/ui/core/layouts/layout'
import TabsWrapper from '/src/ui/core/layouts/tabs_wrapper'
import LayoutPanels from '/src/ui/core/layouts/layout_panels'
import InspectionsSidePanel from '/src/ui/domain/inspections/inspections_side_panel'
import TemplatableGrid from '/src/ui/core/grid/templatable_grid'
import StatusFilterLabel from '/src/ui/core/grid/statuses_filter_label'
import {
  deleteMenuItem,
  printMenuItem,
  clearTemplatePreferencesMenuItem,
  editModalMenuItem,
  duplicateModalMenuItem
} from '/src/ui/core/grid/context_menu_entries'
import useConfirmationModal from '/src/ui/core/popups/confirmation_modal'
import NewButton from '/src/ui/core/icons/new_button'
import { notifyError } from '/src/ui/core/dialogs/notifications'
import MoreActionsIcon from '/src/ui/core/icons/more_actions_icon'
import BatchAcceptanceIcon from '/src/ui/core/icons/batch_acceptance_icon'
import useConfigurePrintGridFile from '/src/ui/core/popups/configure_print_grid_file'
import useClearTemplatePreferences from '/src/ui/core/popups/clear_template_preferences'
import InspectionModel from '/src/models/inspection'
import { checkInspectionFinished } from '/src/models/concerns/inspection_filter'
import { filterVisibleOnWeb } from '/src/models/concerns/eav_section'
import { revertMenuItem } from '/src/ui/domain/inspections/revert_menu_item'
import useInspectionStatusFilter from '/src/ui/domain/inspections/inspection_status_filter'
import InspectionFormModal from '/src/ui/domain/inspections/inspection_form_modal'
import useInspectionAcceptancePopup from '/src/ui/domain/inspections/inspection_acceptance_popup'
import useScaffoldingGridFilter from '/src/hooks/scaffolding_grid_filter'
import { indexify } from '/src/utils/array'
import '/src/static/css/core/layouts/shadowed_page.css'
import '/src/static/css/core/grid/grid.css'

export default function Inspections() {
  const { fetch } = useFetch()
  const model = new InspectionModel()
  const templateId = useQueryParamWithPrevious('eav_template_id')
  const [selectItemId, setSelectItemId] = useQueryParam('select_item_id', NumberParam)
  const { requestId } = useRequestsPinned()
  const { filter: requestFilter, gridTitle, labels: requestLabel } = useRequestGridFilter({
    route: model.route,
    templateId
  })
  const { labels: scaffoldingLabel, filter: urlFilterArray, unpinScaffolding } = useScaffoldingGridFilter()
  const labels = scaffoldingLabel.length ? scaffoldingLabel : requestLabel
  const dataFilter = urlFilterArray.length ? urlFilterArray : requestFilter
  const history = useHistory()
  const { status, responseData, fetchAPI } = useFetchAPI('eav_sections')
  const loadingTranslations = useTranslations()
  const [inspectStatuses] = useStore('inspect_statuses')
  const statuses = inspectStatuses ? Object.values(inspectStatuses) : []
  const pendingInspection = statuses.find((e) => e.i18n_id === 'pending')

  const renderAcceptanceModal = useInspectionAcceptancePopup()

  const [inspectionTemplate, setInspectionTemplate] = useState({})
  const [gridColumns, setGridColumns] = useState([])
  const [gridDataSource, setGridDataSource] = useState()
  const [selectedItem, setSelectedItem] = useState()
  const [filter, setFilter] = useState(requestFilter)
  const [sectionsColumns, setSectionsColumns] = useState([])
  const [sidePanelDataItem, setSidePanelDataItem] = useState()
  const [columnStyles, setColumnStyles] = useState(SIDE_PANEL_CLOSED)
  const [showConfirmation, renderConfirmation] = useConfirmationModal()
  const [selectedStatusFilterOption, setSelectedStatusFilterOption] = useState()
  const { filterIcon, clearFilters } = useInspectionStatusFilter(filter, setFilter, setSelectedStatusFilterOption)
  const [openConfigurationPopup, printGridPopup, isPrintable] = useConfigurePrintGridFile(
    gridColumns,
    gridDataSource,
    model
  )

  const [onClearTemplatePreferencesClick, clearTemplatePreferencesPopup] = useClearTemplatePreferences(templateId)

  const moreActionsMenuItems = [
    printMenuItem(openConfigurationPopup, () => isPrintable),
    clearTemplatePreferencesMenuItem(onClearTemplatePreferencesClick)
  ]

  const clearAllFiltersCallback = useCallback(() => {
    clearFilters()
    unpinScaffolding()
  }, [clearFilters, unpinScaffolding])

  const icons = [
    'batch_assigner',
    <BatchAcceptanceIcon />,
    filterIcon,
    <MoreActionsIcon items={moreActionsMenuItems} />,
    <NewButton />
  ]

  const filters = useMemo(() => [...filter, ...dataFilter], [filter, dataFilter])

  const statusFilterLabel = useCallback(
    () =>
      selectedStatusFilterOption &&
      selectedStatusFilterOption.length > 0 && (
        <StatusFilterLabel
          key="inspection-status-filter"
          options={selectedStatusFilterOption}
          onClearFilters={clearFilters}
        />
      ),
    [selectedStatusFilterOption]
  )

  const isPending = (data) => pendingInspection && data.inspect_status_id === pendingInspection.id

  const handleCloseSidePanel = useCallback(() => {
    setSelectedItem()
    setSidePanelDataItem()

    dispatch(BusEvents.SIDE_PANEL_CLOSED)

    setColumnStyles(SIDE_PANEL_CLOSED)
  }, [])

  const onTemplateChange = (template) => {
    setInspectionTemplate(template)
  }

  const onRowClick = useCallback((e) => {
    setSelectedItem((prevSelectedItem) => {
      const isSidePanelOpened = prevSelectedItem && prevSelectedItem.id === e.dataItem.id
      const newItem = isSidePanelOpened ? undefined : e.dataItem

      setSidePanelDataItem(newItem)

      if (newItem) setColumnStyles(SIDE_PANEL_OPENED)
      else handleCloseSidePanel()

      return newItem
    })
  }, [])

  const fetchRequests = useCallback(
    ({ data: dataSource }) => {
      if (
        !dataSource ||
        dataSource.length === 0 ||
        dataSource.every((dataItem) => (dataItem?.request_id ? dataItem?.request?.request_status_id : true))
      )
        return

      const queryParams = {
        requestAction: 'READ',
        httpAction: 'get',
        query: {
          where: { id: dataSource.map((request) => request.request_id) }
        }
      }

      fetch('requests', queryParams, {
        onSuccess: ({ data: { data } }) => {
          const modelName = model.paramName

          const requestsById = indexify(data, 'id')
          const onSetDataSource = (prevDataSource) => ({
            ...prevDataSource,
            data: prevDataSource.data.map((item) => ({
              ...item,
              request: requestsById[item.request_id]
            }))
          })

          dispatch({
            type: BusEvents.UPDATE_GRID_DATA_SOURCE,
            payload: { modelName, onSetDataSource }
          })
        }
      })
    },
    [fetch, model]
  )

  const onDataSourceUpdated = useCallback(
    (dataSource) => {
      if (dataSource.loading) return
      dataSource.data.map(
        (data) =>
          // eslint-disable-next-line no-param-reassign
          (data.checkboxDisabled = checkInspectionFinished(data, inspectStatuses))
      )

      fetchRequests(dataSource)

      const newSelectedItem = dataSource.data.filter(
        (item) => item.id === selectItemId || (selectedItem && item.id === selectedItem.id)
      )[0]

      if (!newSelectedItem) handleCloseSidePanel()
      else {
        if (selectItemId) setSelectItemId()
        setSelectedItem(newSelectedItem)
        setSidePanelDataItem(newSelectedItem)
        setColumnStyles(SIDE_PANEL_OPENED)
      }

      setGridDataSource(dataSource)
    },
    [
      selectedItem,
      setSelectedItem,
      setSidePanelDataItem,
      setColumnStyles,
      selectItemId,
      setSelectItemId,
      handleCloseSidePanel,
      inspectStatuses
    ]
  )

  const actions = [
    editModalMenuItem(isPending, (e, dataItem) => {
      dispatch({
        type: BusEvents.OPEN_GRID_FORM_MODAL,
        modelName: model.paramName,
        payload: { dataItem, formType: 'edit' }
      })
    }),
    duplicateModalMenuItem(
      () => true,
      (e, dataItem) => {
        dispatch({
          type: BusEvents.OPEN_GRID_FORM_MODAL,
          modelName: model.paramName,
          payload: { dataItem, formType: 'duplicate' }
        })
      }
    ),
    deleteMenuItem(history, model, showConfirmation, handleCloseSidePanel),
    revertMenuItem(history, model, showConfirmation, undefined, (item) => !isPending(item))
  ]

  useEffect(() => {
    if (!templateId) return

    const params = {
      httpAction: 'get',
      additionalResource: { path: 'eav_columns' },
      query: { where: { eav_template_id: templateId } }
    }

    fetchAPI(params)
    handleCloseSidePanel()
  }, [templateId])

  useEffect(() => {
    if (loadingTranslations || status !== 'SUCCESS') return

    const newSectionsColumns = filterVisibleOnWeb(responseData.data[0])
    setSectionsColumns(newSectionsColumns)
  }, [status, responseData, loadingTranslations])

  useBus(
    BusEvents.NEW_BUTTON_CLICKED,
    () => {
      if (!templateId) {
        notifyError(I18n.t('templates.no_template'))
        return
      }
      dispatch({
        type: BusEvents.OPEN_GRID_FORM_MODAL,
        modelName: model.paramName,
        payload: { formType: 'new' }
      })
    },
    [templateId, history]
  )

  return (
    <Layout key={'inspections'} showNewButton>
      {renderConfirmation()}
      {renderAcceptanceModal()}
      {printGridPopup}
      {clearTemplatePreferencesPopup()}
      <TabsWrapper tabType={model.templateType} onTemplateChange={onTemplateChange} />
      <LayoutPanels wrapperClass="side-panel-wrapper" columnStyles={columnStyles}>
        <div className={selectedItem ? 'shadowed-page' : 'unshadowed-page'}>
          <TemplatableGrid
            labels={labels.concat([statusFilterLabel()])}
            filter={filters}
            clearAllFiltersCallback={clearAllFiltersCallback}
            gridTitle={gridTitle}
            contextMenuItems={actions}
            model={model}
            icons={icons}
            onRowClick={onRowClick}
            selectedItem={selectedItem}
            selectFiltering={false}
            onGridColumns={setGridColumns}
            onDataSource={onDataSourceUpdated}
            selecting
          />
        </div>
        <React.Fragment>
          {sidePanelDataItem && (
            <InspectionsSidePanel
              dataItem={sidePanelDataItem}
              sections={sectionsColumns}
              onClose={handleCloseSidePanel}
              actions={actions}
            />
          )}
        </React.Fragment>
      </LayoutPanels>
      <InspectionFormModal modelParamName={model.paramName} template={inspectionTemplate} />
    </Layout>
  )
}
