/* eslint-disable max-len */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import lodash from 'lodash'
import useFetch from '/src/hooks/api/fetch'
import I18n from '/src/utils/translations'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import Modal from '/src/ui/core/popups/modal'
import EmptyTemplatesBody from '/src/ui/domain/requests/inspection_template/empty_templates_body'
import ChooseInspectionTemplateHeader from '/src/ui/domain/requests/inspection_template/choose_inspection_template_header'
import ChooseInspectionTemplateBody from '/src/ui/domain/requests/inspection_template/choose_inspection_template_body'
import '/src/static/css/core/popups/choose_inspection_template_modal.css'

export default function ChooseInspectionTemplateModal({ modelName, setInspectionTemplate, inspectedType }) {
  const [openChooseInspectionModal, setOpenChooseInspectionModal] = useState(false)
  const [inspectionTemplates, setInspectionTemplates] = useState(null)
  const [templateCategoryName, setTemplateCategoryName] = useState(null)
  const [formDataItem, setFormDataItem] = useState()
  const loading = {
    status: inspectionTemplates === null,
    text: I18n.t('requests.create_inspection_modal.loading')
  }
  const hasTemplates = inspectionTemplates && inspectionTemplates.length
  const { isNil } = lodash
  const { fetch } = useFetch()

  // this function will certainly have to be adjusted with future pages doing this
  const isInBatch = !isNil(formDataItem?.dataItem.items)

  const getDataItem = () => {
    const { dataItem: completeDataItem } = formDataItem
    const selectedItemsLength = isInBatch ? completeDataItem.items?.length : 1
    const isNotRequest = inspectedType !== 'Request'

    const dataItems = Array.from({ length: selectedItemsLength }, (_, index) => {
      const currentItem = completeDataItem.items ? completeDataItem.items[index] : completeDataItem
      return {
        inspected_type: inspectedType,
        request_id: isNotRequest ? currentItem.request_id : currentItem.id,
        ...(isNotRequest && {
          responsible_id: currentItem?.responsible_id,
          inspect_date: currentItem?.inspect_date,
          inspected_id: currentItem.id
        })
      }
    })

    return isInBatch ? { items: dataItems } : dataItems[0]
  }

  const openInspectionModalCallback = (_, item) => {
    const dataItem = getDataItem()
    setTemplateCategoryName(null)
    setOpenChooseInspectionModal(false)
    setInspectionTemplate(item)
    dispatch({
      type: BusEvents.OPEN_GRID_FORM_MODAL,
      modelName,
      payload: {
        dataItem,
        formType: 'new',
        opts: { fromExternalModule: true }
      }
    })
  }

  const fetchInspectionTemplate = () => {
    const params = {
      requestAction: 'READ',
      httpAction: 'get',
      query: {
        where: { template_type: 'inspection' }
      }
    }

    fetch('eav_templates', params, {
      onSuccess: ({ data }) => {
        const responseData = data.data
        setInspectionTemplates(responseData)
      }
    })
  }

  useBus(BusEvents.OPEN_MODAL, ({ payload, triggeredModelName }) => {
    if (!triggeredModelName || triggeredModelName !== modelName) return

    if (payload) {
      setFormDataItem(payload)
    } else {
      setFormDataItem()
    }

    setOpenChooseInspectionModal(true)
    fetchInspectionTemplate()
  })

  const onCloseCallback = () => {
    setTemplateCategoryName(null)
    if (templateCategoryName) return
    setOpenChooseInspectionModal(false)
  }

  return (
    openChooseInspectionModal && (
      <div
        className={`confirmation-modal choose-inspections-modal ${
          templateCategoryName ? 'choose-datasheet-category' : ''
        }`}
      >
        <Modal
          height={590}
          width={850}
          title={
            isInBatch
              ? I18n.t('requests.create_inspection_modal.plural_title')
              : I18n.t('requests.create_inspection_modal.title')
          }
          onClose={onCloseCallback}
          loading={loading}
          className=""
          modalBodyClassName="choose-inspections-modal-body-wrapper"
          sizeInPx
          closable
          header={<ChooseInspectionTemplateHeader templateCategoryName={templateCategoryName} />}
        >
          <div className="body">
            {hasTemplates ? (
              <ChooseInspectionTemplateBody
                inspectionTemplates={inspectionTemplates}
                openInspectionModalCallback={openInspectionModalCallback}
                openTemplateCategory={(_, item) => setTemplateCategoryName(item)}
                templateCategoryName={templateCategoryName}
              />
            ) : (
              <EmptyTemplatesBody />
            )}
          </div>
        </Modal>
      </div>
    )
  )
}

ChooseInspectionTemplateModal.propTypes = {
  modelName: PropTypes.string.isRequired,
  setInspectionTemplate: PropTypes.func.isRequired,
  inspectedType: PropTypes.string.isRequired
}
