/* eslint-disable max-lines-per-function */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useStore } from 'react-context-hook'
import { normalizeDateToString } from '/src/utils/project_formatter'
import I18n from '/src/utils/translations'

/**
 * This custom hook implements a way of creating sections for schedule forms
 * @return [dataItem, setDataItem, sections]
 */
export default function useInspectionAcceptancePopupFields(defaultValues, updateForm) {
  const [formData, setFormData] = useState({})
  const [employees] = useStore('employees')
  const [user] = useStore('user')

  const defaultDate = useCallback(() => {
    return defaultValues && defaultValues.inspect_date ? defaultValues.inspect_date : normalizeDateToString(new Date())
  }, [defaultValues])

  const responsibleMetadata = () => {
    const employeesArray = Object.values(employees)
    return JSON.stringify(employeesArray)
  }

  const defaultResponsible = useCallback(() => {
    const employeesArray = Object.values(employees).filter((employee) => employee.is_inspection_responsible)
    if (!defaultValues || !defaultValues.responsible_id)
      return employeesArray.find((employee) => {
        return user && user.employee_id && employee.id === user.employee_id
      })

    const responsible = employeesArray.find((employee) => employee.id === defaultValues.responsible_id)
    return responsible ? responsible.id : null
  }, [defaultValues, employees, user])

  const onChange = useCallback(
    (description, value) => {
      if (formData[description] === value) return
      setFormData((prevFormData) => ({ ...prevFormData, [description]: value }))
    },
    [formData]
  )

  useEffect(() => {
    setFormData({
      id: defaultValues?.id,
      inspect_date: defaultDate(),
      responsible_id: defaultResponsible()
    })
  }, [updateForm, defaultValues, defaultDate, defaultResponsible])

  const sections = useMemo(() => {
    return [
      {
        id: 1,
        eav_columns: [
          {
            id: 1,
            description: 'inspect_date',
            title: I18n.t('requests.schedule_form.date'),
            columnSize: 'width-100',
            onChange,
            type: 'date',
            default: defaultDate(),
            required: true
          },
          {
            id: 2,
            description: 'responsible_id',
            title: I18n.t('inspections.responsible_id'),
            foreignKey: 'employees',
            foreignAttribute: 'responsible_id',
            required: true,
            searchFields: ['name', 'last_name'],
            searchExtraQuery: {
              where: { is_inspection_responsible: 1 }
            },
            searchRoute: 'employees',
            metadata: responsibleMetadata(),
            onChange,
            textDisplayFields: ['full_name'],
            textField: 'full_name',
            type: 'search',
            default: defaultResponsible()
          }
        ]
      }
    ]
  }, [defaultResponsible, onChange, responsibleMetadata, defaultDate])

  return [formData, sections]
}
